import { interpolate } from '@/modules/cms/functions';

export default function (args) {
  if (!args.url) {
    console.warn('cms/actions/http2: no url specified');
    return;
  }

  let url = args.url;
  let method = args.method ? args.method.toUpperCase() : 'GET';
  let body = args.body;


  let httpClient = this.$store.state.api ? this.$store.state.api : this.$store.state.httpClient;
  if (!httpClient) {
    console.warn('cms/actions/http2: no httpClient found');
    return;
  }

  // return this.$store.state.api
  return httpClient
    .fetch(url, { method, body })
    .then(response => response.json())
    .then(incoming => {
      if (args.map && Array.isArray(incoming)) {
        incoming = incoming.map(item => interpolate(args.map, { 'item': item }));
      }

      return incoming;
    });
}